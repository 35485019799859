import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { RouterModule } from '@angular/router'
import { FrameRouterModule } from 'se-fe-frame-router'

import { AppComponent } from './app.component'
import { NotFoundComponent } from './not-found.component'
import { baseHrefProvider } from './base-href.provider'
import { environment } from '../environments/environment'
import { routes } from './routes'
import { SeAnalyticsModule } from 'se-analytics'

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FrameRouterModule.forRoot(environment),
    HttpClientModule,
    SeAnalyticsModule.forRoot({
      platProp: 'se_eligibility_ui',
      platformSubSec: 'HQ',
      currentEnv: environment.env
    }),
    RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })
  ],
  exports: [
    RouterModule
  ],
  providers: [
    baseHrefProvider,
    { provide: 'Window', useValue: window }
  ],
  declarations: [
    AppComponent,
    NotFoundComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
